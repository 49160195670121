import stickybits from 'stickybits';

document.addEventListener('DOMContentLoaded', () => {
    const el = document.querySelectorAll('.js-sticky');

    const init = () => {
        for (let i = 0; i < el.length; i ++) {
            let stickybit = stickybits(el[i], {
	            stickyBitStickyOffset: document.querySelector('.js-topbar').clientHeight
            });
        }
    };
    window.addEventListener('load', () => {
        el.length > 0 ? init() : false;
    })
}, false);
