const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

document.addEventListener('DOMContentLoaded', () => {

    const nav = document.querySelector('.js-nav'),
          hamburger = document.querySelector('.js-hamburger'),
          menu = document.querySelector('.js-menu');

    const init = () => {

        window.hideMenu = function() {
            document.querySelector('.js-nav').classList.remove('is-visible');
            document.querySelector('.js-hamburger').classList.remove('is-active');
            
            document.removeEventListener('click', clickOutside);
            document.documentElement.classList.remove('menu-opened');
            
            setTimeout(() => {
                document.querySelector('.js-nav').classList.remove('is-block');
                document.querySelector('.js-nav').classList.remove('is-animated');
            }, 500);
            
            enableBodyScroll(menu);
        };

        const showMenu = () => {
            nav.classList.add('is-block');
            hamburger.classList.add('is-active', 'is-disabled');
            document.documentElement.classList.add('menu-opened');

            setTimeout(function() {
                nav.classList.add('is-visible');
                document.addEventListener('click', clickOutside);
//                showLinks();
            }, 10);
            
/*
            const showLinks = function() {
                const blocks = menu.querySelectorAll("li");
                const tl = gsap.timeline().delay(.1).eventCallback("onComplete", () => {
                          nav.classList.add('is-animated');
                          hamburger.classList.remove('is-disabled');
                      })

                .from(blocks, {
                    x: 50,
                    opacity: 0,
                    stagger: {
                        each: 0.1
                    }
                });                
            };
*/

            disableBodyScroll(menu);
        };

        const toggleMenu = function(e) {
            nav.classList.contains('is-visible') ? hideMenu() : showMenu();
        };

        hamburger.addEventListener('click', toggleMenu);
        
        const clickOutside = function(e) {
            if (!e.target.closest('.js-nav')) {
                hideMenu();
        	}
        };

        // Hide menu on ESC
        document.addEventListener('keydown', (evt) => {
            // evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key == "Escape" || evt.key == "Esc");
            } else {
                isEscape = (evt.keyCode == 27);
            }
            if (isEscape) {
                hideMenu();
            }
        });
    };

    nav ? init() : false;

}, false);