import Swiper from 'swiper';
import { FreeMode, Navigation } from 'swiper/modules';
import 'swiper/modules/free-mode.scss';
import 'swiper/swiper.scss';

document.addEventListener('DOMContentLoaded', () => {
    
    const reviews = document.querySelector('.js-reviews');
   
    const reviews_carousel = () => {
        const swiper = new Swiper(reviews, {
            modules: [FreeMode, Navigation],
            createElements: true,
            freeMode: true,
            navigation: {
                nextEl: reviews.parentNode.querySelector('.swiper-button-next'),
                prevEl: reviews.parentNode.querySelector('.swiper-button-prev')
            },
            slidesPerView: 'auto',
            speed: 500
        });
    };
   
    window.carousels = () => {
        reviews ? reviews_carousel() : false;
    }
}, false)
