document.addEventListener('DOMContentLoaded', ()=> {

    const timer = document.getElementById('timer');

    const countdown = (endDate) => {
        let days, hours, minutes, seconds;
        
        endDate = new Date(endDate).getTime();

        console.clear();
        
        if (isNaN(endDate)) {
            return;
        }
        
        const calculate = ()=> {
            let startDate = new Date();
            startDate = startDate.getTime();
            
            let timeRemaining = parseInt((endDate - startDate) / 1000);
            
            //console.log(timeRemaining);
            
            if (timeRemaining >= 0) {

                days = parseInt(timeRemaining / 86400);
                timeRemaining = (timeRemaining % 86400);
                hours = parseInt(timeRemaining / 3600);
                timeRemaining = (timeRemaining % 3600);
                minutes = parseInt(timeRemaining / 60);
                timeRemaining = (timeRemaining % 60);
                seconds = parseInt(timeRemaining);
                
                let day_left = parseInt(days, 10);                
                day_left === 0 ? document.getElementById("days").classList.add('is-inactive') : false;
                document.getElementById("days").querySelector('.js-num').innerHTML = day_left;
                
                let hours_left = parseInt(("0" + hours).slice(-2));
                if (hours_left < 1 && day_left === 0) {
                    document.getElementById("hours").classList.add('is-inactive');
                    document.getElementById("hours").querySelector('.js-num').innerHTML = '0';
                } else {
                    document.getElementById("hours").querySelector('.js-num').innerHTML = hours_left;
                }
                
                let minutes_left = parseInt(("0" + minutes).slice(-2));
                if (minutes_left < 1 && hours_left < 1 && days_left === 0) {
                    document.getElementById("minutes").classList.add('is-inactive');
                    document.getElementById("minutes").querySelector('.js-num').innerHTML = '0';
                } else {
                    document.getElementById("minutes").querySelector('.js-num').innerHTML = minutes_left;
                }

                let seconds_left = parseInt(("0" + seconds).slice(-2));
                if (seconds_left < 1 && minutes_left < 1 && hours_left < 1 && days_left === 0) {
                    document.getElementById("seconds").classList.add('is-inactive');
                    document.getElementById("seconds").querySelector('.js-num').innerHTML = '0';
                } else {
                    document.getElementById("seconds").querySelector('.js-num').innerHTML = seconds_left;
                }
            } else {
                clearInterval(myInterval);
                
                for (let i = 0; i < document.querySelectorAll('.js-num').length; i++) {
                    document.querySelectorAll('.js-num')[i].parentNode.classList.add('is-inactive');
                    document.querySelectorAll('.js-num')[i].innerHTML = '0';
                }
                
                return;
            }
        }
        
        let myInterval =setInterval(calculate, 1000);
    }
        
    timer ? countdown(timer.getAttribute('data-endtime')) : false
    
}, false);
