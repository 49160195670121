import GLightbox from '../../node_modules/glightbox/dist/js/glightbox.js';
require('../../node_modules/glightbox/dist/css/glightbox.css');

document.addEventListener('DOMContentLoaded', () => {

    if (document.getElementsByClassName('js-glightbox').length > 0) {
        const image = GLightbox({
            selector: '.js-glightbox'
        });
    }

   
}, false);
